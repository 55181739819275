//
// _menu.scss
// 

.metismenu {
    margin: 0;

    li {
        display: block;
        width: 100%;
    }

    .mm-collapse {
        display: none;

        &:not(.mm-show) {
            display: none;
        }

        &.mm-show {
            display: block
        }
    }

    .mm-collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-timing-function: ease;
        transition-duration: .35s;
        transition-property: height, visibility;
    }
}


.vertical-menu {
    width: $sidebar-width;
    z-index: 1002;
    background: $sidebar-bg;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: $header-height;
    box-shadow: $box-shadow;
}

.main-content {
    margin-left: $sidebar-width;
    overflow: hidden;

    .content {
        padding: 0 15px 10px 15px;
        margin-top: $header-height;
    }
}


#sidebar-menu {
    padding: 10px 0 30px 0;

    .mm-active {
        >.has-arrow {
            &:after {
                transform: rotate(-180deg);
            }
        }
    }

    .has-arrow {
        &:after {
            content: "\F140";
            font-family: 'Material Design Icons';
            display: block;
            float: right;
            transition: transform .2s;
            font-size: 1rem;
        }
    }

    ul {
        li {
            a {
                display: block;
                padding: .7rem 1.5rem;
                color: $sidebar-menu-item-color;
                position: relative;
                font-size: 15px;
                transition: all .4s;

                svg {
                    min-width: 1.75rem;
                }

                .uim-svg{
                    fill: $sidebar-menu-item-icon-color !important;
                }

                &:hover {
                    color: $sidebar-menu-item-hover-color;

                    i {
                        color: $sidebar-menu-item-hover-color;
                    }
                }
            }

            .badge {
                margin-top: 4px;
            }

            ul.sub-menu {
                padding: 0;

                li {

                    a {
                        padding: .4rem 1.5rem .4rem 3.7rem;
                        font-size: 13.5px;
                        color: $sidebar-menu-sub-item-color;
                    }

                    ul.sub-menu {
                        padding: 0;

                        li {
                            a {
                                padding: .4rem 1.5rem .4rem 4.5rem;
                                font-size: 13.5px;
                            }
                        }
                    }
                }
            }
        }

    }
}

.menu-title {
    padding: 12px 20px !important;
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
    font-size: 11px;
    text-transform: uppercase;
    color: $sidebar-menu-item-color;
    font-weight: $font-weight-semibold;
}

.mm-active {
    color: $sidebar-menu-item-active-color !important;
    .active {
        color: $sidebar-menu-item-active-color !important;
    }

}
.sidebar-section {
    padding: .625rem 1.5rem;
}

@media (max-width: 992px) {
    .vertical-menu {
        display: none;
    }

    .main-content {
        margin-left: 0 !important;
    }

    body.sidebar-enable {
        .vertical-menu {
            display: block;
        }
    }
}

// Enlarge menu
.vertical-collpsed {
    min-height: 1200px;
    
    .main-content {
        margin-left: $sidebar-collapsed-width;
    }

    .navbar-brand-box {
        width: $sidebar-collapsed-width !important;
    }

    .logo {
        span.logo-lg {
            display: none;
        }

        span.logo-sm {
            display: block;
        }
    }

    // Side menu
    .vertical-menu {
        position: absolute;
        width: $sidebar-collapsed-width !important;
        z-index: 5;

        .simplebar-mask,
        .simplebar-content-wrapper {
            overflow: visible !important;
        }

        .simplebar-scrollbar {
            display: none !important;
        }

        .simplebar-offset {
            bottom: 0 !important;
        }

        // Sidebar Menu
        #sidebar-menu {

            .menu-title,
            .badge,
            .collapse.in,
            .sidebar-section {
                display: none !important;
            }

            .nav.collapse {
                height: inherit !important;
            }

            .has-arrow {
                &:after {
                    display: none;
                }
            }

            > ul {
                > li {
                    position: relative;
                    white-space: nowrap;

                    > a {
                        padding: 15px 20px;
                        min-height: 55px;
                        transition: none;
                        
                        &:hover,
                        &:active,
                        &:focus {
                            color: $sidebar-menu-item-hover-color;
                        }

                        svg {
                            font-size: 1.25rem;
                            margin-left: 2px;
                        }

                        
                        &.mm-active{
                            background: $sidebar-menu-item-active-bg;
                        }

                        span {
                            display: none;
                            padding-left: 25px;
                            &.uim-svg{
                                padding-left: 0px;
                                display: inline-block;
                            }
                        }
                    }

                    &:hover {
                        > a {
                            position: relative;
                            width: calc(190px + #{$sidebar-collapsed-width});
                            color: $primary;
                            background-color: $sidebar-menu-item-active-bg;
                            transition: none;

                         

                            span {
                                display: inline;
                            }
                        }

                        >ul {
                            display: block;
                            left: $sidebar-collapsed-width;
                            position: absolute;
                            width: 190px;
                            height: auto !important;
                            box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);

                            ul {
                                box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);
                            }

                            a {
                                box-shadow: none;
                                padding: 8px 20px;
                                position: relative;
                                width: 190px;
                                z-index: 6;
                                color: $sidebar-menu-sub-item-color;

                                &:hover {
                                    color: $sidebar-menu-item-hover-color;
                                }
                            }
                        }
                    }
                }

                ul {
                    padding: 5px 0;
                    z-index: 9999;
                    display: none;
                    background-color: lighten($sidebar-bg, 2%);

                    li {
                        &:hover {
                            >ul {
                                display: block;
                                left: 190px;
                                height: auto !important;
                                margin-top: -36px;
                                position: absolute;
                                width: 190px;
                            }
                        }

                        >a {
                            span.pull-right {
                                position: absolute;
                                right: 20px;
                                top: 12px;
                                transform: rotate(270deg);
                            }
                        }
                    }

                    li.active {
                        a {
                            color: $gray-100;
                        }
                    }
                }
            }
        }

    }
}


// Dark sidebar

body[data-sidebar="dark"] {

    .navbar-brand-box {
        background: $sidebar-dark-bg;
    }

    .logo-dark {
        display: $logo-light-display;
    }

    .logo-light {
        display: $logo-dark-display;
    }

    .vertical-menu {
        background: $sidebar-dark-bg;
    }

    #sidebar-menu {
    
        ul {
            li {
                a {
                    color: $sidebar-dark-menu-item-color;
    
                    &:hover {
                        color: $sidebar-dark-menu-item-hover-color;

                    }

                    .icons-sm .uim-svg {
                        fill: $sidebar-dark-menu-item-active-color !important;
                    }
                }

                ul.sub-menu {
                    li {

                        a {
                            color: $sidebar-dark-menu-sub-item-color;

                            &:hover {
                                color: $sidebar-dark-menu-item-hover-color;
                            }
                        }
                    }
                }
            }
        }
    }
    // Enlarge menu
    &.vertical-collpsed {
        min-height: 1200px;
        // Side menu
        .vertical-menu {
            // Sidebar Menu
            #sidebar-menu {

                > ul {
                    > li {
                        
                        
                        &:hover {
                            > a {
                                background: $sidebar-dark-menu-item-active-bg;
                                color: $sidebar-dark-menu-item-hover-color;
                            }

                            >ul {
                                a{
                                    color: $sidebar-dark-menu-sub-item-color;
                                    &:hover{
                                        color: $sidebar-dark-menu-item-hover-color;
                                    }
                                }
                            }
                        }

                        >a.mm-active{
                            background: $sidebar-dark-menu-item-active-bg;
                        }

                    }

                    ul{
                        background-color: $sidebar-dark-bg;
                    }
                    
                }

                ul{
                    
                    li{
                        &.mm-active .active{
                            color: $sidebar-dark-menu-item-active-color !important;
                            
                        }
                    }
                }

            }

        }
    }
    .mm-active {
        color: $sidebar-dark-menu-item-active-color !important;

        .active {
            color: $sidebar-dark-menu-item-active-color !important;
        }
    }

    .menu-title {
        color: $sidebar-dark-menu-item-icon-color;
    }

    .page-title-box{
        background-color: rgba($primary, 0.1);

        .page-title{
            color: $gray-700;
        }

        .breadcrumb-item {
            > a {
                color: $gray-700;
            }
            &.active {
                color: $breadcrumb-active-color;
            }
        }
    }

}


// Small Sidebar

body[data-sidebar-size="small"] {
    .navbar-brand-box{
        width: $sidebar-width-sm;
    }
    .vertical-menu{
        width: $sidebar-width-sm;
        text-align: center;
    }
    .main-content {
        margin-left: $sidebar-width-sm;
    }
    .footer {
        left: $sidebar-width-sm;
    }
    .has-arrow:after,
    .badge {
        display: none !important;
    }

    #sidebar-menu {
        ul li {
            &.menu-title{
                background-color: darken($sidebar-bg, 2%);
            }
            a{
                .icons-sm{
                    display: block !important;
                }
            }
            ul.sub-menu {
                li a{
                    padding-left: 1.5rem;
                }
            }
        }
    }
    &.vertical-collpsed {
        .main-content {
            margin-left: $sidebar-collapsed-width;
        }
        .vertical-menu {
            #sidebar-menu{
                text-align: left;
                >ul{
                    >li{
                        >a {
                            .icons-sm{
                                display: inline-block !important;
                            }
                        }
                    }
                }
            }
        }
        .footer {
            left: $sidebar-collapsed-width;
        }
    }
}


// Colored Sidebar

body[data-sidebar="colored"] {
    .navbar-brand-box{
        background-color: $primary;
    }

    .logo-dark{
        display: none;
    }

    .logo-light{
        display: block;
    }

    .vertical-menu{
        background-color: $primary;
    }

    .menu-title{
        color: rgba($white, 0.4);
    }

    #sidebar-menu {
        ul {
            li {
                a{
                    color: rgba($white, 0.6);

                    .uim-svg{
                        fill: $white !important;
                    }
                }

                .mm-active{
                    color: $white !important;

                    .active{
                        color: $white !important;
                    }
                }

                ul.sub-menu {
                    li {
                        a{
                            color: rgba($white, 0.4);
                        }
                    }
                }
            }
        }
    }

    &.vertical-collpsed {
        .vertical-menu {
            #sidebar-menu{
                >ul{
                    >li{
                        &:hover{
                            >a{
                                background-color: lighten($primary, 2%);
                                color: $white;
                            }
                        }

                        >a.mm-active{
                            background-color: lighten($primary, 2%);
                        }
                    }
                }

                ul{
                    li{
                        .mm-active{
                            .active{
                                color: $primary !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .page-title-box{
        background-color: rgba($primary, 0.1);

        .page-title{
            color: $gray-700;
        }

        .breadcrumb-item {
            > a {
                color: $gray-700;
            }
            &.active {
                color: $breadcrumb-active-color;
            }
        }
    }
}
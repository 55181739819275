// 
// Extras pages.scss
//

.gallery-categories-filter {
    li {
        list-style: none;
        display: inline-block;
        a {
            display: block;
            color: $primary !important;
            background-color: rgba($primary, 0.1);
            border: 1px solid rgba($primary, 0.1);
            padding: 0px 15px;
            margin: 5px 3px;
            cursor: pointer;
            line-height: 34px;
            border-radius: 4px;
            transition: all 0.4s;
            &.active{
                background-color: $primary;
                border-color: $primary;
                color: $white !important;
            }
        }
    }
}

.gallery-box {
    position: relative;
    border-radius: 7px;
    overflow: hidden;
    a {
        .gallery-overlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: $primary;
            opacity: 0;
            transition: all 0.5s;
        }

        .overlay-content{
            position: absolute;
            left: 0;
            right: 0;
            bottom: -60px;
            transition: all 0.5s;
            padding: 14px;
            margin: 0px 12px;
            border-radius: 4px;
            text-align: center;
            color: rgba($white, 0.7);
            
            .overlay-title{
                color: $white;
            }
        }

        .gallery-overlay-icon {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-85%);
            transition: all 0.5s;
            i{
                font-size: 28px;
            }
        }

        &:hover{
            .gallery-overlay {
                opacity: 0.7;
            }

            .overlay-content{
                bottom: 0px;
            }
        }
    }
}

.arrow-shape{
    position: relative;
    &:after{
        content: "";
        position: absolute;
        width: 30px;
        height: 100%;
        background-color: $danger;
        right: -22px;
        top: 0;
        clip-path: polygon(0% 0%, 40% 0%, 100% 50%, 40% 100%, 0% 100%);
    }
}
//
// Form editors.scss
//

// Tinymce 
.tox-tinymce {
  border: 1px solid $input-border-color !important;
}

.tox {
  .tox-menubar, .tox-edit-area__iframe, .tox-statusbar{
    background-color: $card-bg !important;
  }
  .tox-menubar {
    background: none !important;
    border-bottom: 1px solid $input-border-color;
  }
  .tox-mbtn{
    color: $gray-700 !important;
      &:hover:not(:disabled):not(.tox-mbtn--active){
          background-color: $gray-300 !important;
      }
  }
  .tox-tbtn{
      &:hover{
          background-color: $gray-300 !important;
      }
  }

  .tox-toolbar, .tox-toolbar__overflow, .tox-toolbar__primary{
    background-color: $card-bg !important;
    background: none !important;
    border-bottom: 1px solid $input-border-color;
  }

  .tox-tbtn{
    color: $gray-700 !important;
    svg{
      fill: $gray-700 !important;
    }
  }

  .tox-edit-area__iframe,
  .tox-toolbar-overlord {
    background-color: $card-bg !important;
  }

  .tox-statusbar a, .tox-statusbar__path-item, .tox-statusbar__wordcount{
    color: $gray-700 !important;
  }

  .tox-split-button:hover {
    box-shadow: none !important;
  }

  .tox-tbtn--enabled, 
  .tox-tbtn--enabled:hover {
    background-color: $gray-300 !important;
  }

  .tox-statusbar {
    border-top: 1px solid $input-border-color !important;
  }
}

.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid $input-border-color !important;
}

// Summernote

.note-editor{
  &.note-frame {
    border: 1px solid $input-border-color;
    box-shadow: none;
    margin: 0;

    .note-statusbar {
      background-color: $gray-300;
      border-top: 1px solid $gray-200;
    }

    .note-editing-area{
      .note-editable, .note-codable {
        border: none;
        color: $gray-500;
        background-color: transparent;
      }
    }

  }
}

.note-btn-group{
  .note-btn{
    background-color: $gray-300 !important;
    border-color: $gray-300 !important;
  }
}

.note-status-output {
  display: none;
}

.note-editable {

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.note-popover .popover-content .note-color .dropdown-menu,
.card-header.note-toolbar .note-color .dropdown-menu {
    min-width: 344px;
}

.note-popover{
  border-color: $gray-300;
}

.note-popover .popover-content, 
.card-header.note-toolbar{
  background-color: $gray-300;
}

.note-color-all {
  .note-btn.dropdown-toggle {
    width: 30px !important;
    &:before {
      content: "\F35D";
      font: normal normal normal 24px/1 "Material Design Icons";
      position: absolute;
      left: 2px;
      top: 3px;
    }
  }

  .dropdown-menu {
    padding: 0;
  }
}

.note-editable {
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  padding: $input-padding-y $input-padding-x;

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}


// CK Editor

.cke_top, .cke_bottom{
  background-color: $white !important;
}

.cke_chrome, .cke_top{
  border-color: $input-border-color !important;
}